<template>
	<div class="counter">
		<div class="index-top">
			<div class="index-title">
				<div class="title">充值计算器</div>
				<div class="anniu"><a href="#list"><el-button type="primary">历史记录</el-button></a></div>
			</div>
			<div class="index-prompt">
				<div class="text"></div>
			</div>
		</div>
		<div class="counter-main">
			<div class="gongdan">
				<div class="dan">
					<div class="text"><span>*</span>到账广告币:</div>
					<div class="inputbox">
						<el-autocomplete
							ref="daozhang"
							popper-class="my-autocomplete"
							v-model="state"
							:fetch-suggestions="querySearch"
							placeholder="请输入到账广告币"
							@focus='onfocus'
							@select="handleSelect">
							<i style="margin-top:10px" class="el-icon-caret-bottom" slot="suffix" @click="$refs['daozhang'].focus()"></i>
							<template slot-scope="{ item }">
								<div class="name">{{ item.value }}</div>
							</template>
						</el-autocomplete>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>政策:</div>
					<div class="inputbox">
						<el-autocomplete
							ref="daozhang"
							popper-class="my-autocomplete"
							v-model="zcheradio"
							:fetch-suggestions="zcquerySearch"
							placeholder="请输入政策"
							@focus='onzcfocus'
							@select="zchandleSelect">
							<i style="margin-top:10px" class="el-icon-caret-bottom" slot="suffix" @click="$refs['daozhang'].focus()"></i>
							<template slot-scope="{ item }">
								<div class="name">{{ item.value }}</div>
							</template>
						</el-autocomplete>
					</div>
				</div>
				<div class="dan">
					<div class="text">打款金额:</div>
					<div class="inputbox" style="display:flex;">
						<div class="jieguo">￥{{jieguo}}</div>
						<div v-if="jieguo != 0" class="jieguo" style="margin-left:10px;margin-top:-6px"><el-button v-clipboard:copy="jieguo" v-clipboard:success="onjisqCopy">复制金额</el-button></div>
					</div>
				</div>
				<div class="dan">
					<div class="inputbox anniu">
						<el-button type="primary" @click="onupdateAdv">保存计算记录</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="index-top">
			<div class="index-title">
				<div class="title">充值计算器历史记录</div>
			</div>
			<div class="index-prompt">
				<div class="text"></div>
			</div>
		</div>
		<CounterList ref="CounterList"></CounterList>
		
		<!-- <div class="conterlist" id="list">
			<div class="index-top">
				<div class="index-title">
					<div class="title">充值计算器历史记录</div>
				</div>
				<div class="index-prompt">
					<div class="text"></div>
				</div>
			</div>
			<el-table :data="conterlist" style="width: 100%">
				<el-table-column prop="created_at" label="保存时间" >
					<template slot-scope="scope">
						{{(scope.row.created_at).substr(0,10)}}
					</template>
				</el-table-column>
				<el-table-column prop="adv" label="到账广告币" >
					<template slot-scope="scope">
						{{(scope.row.adv)}}
					</template>
				</el-table-column>
				<el-table-column prop="policy" label="政策" >
					<template slot-scope="scope">
						<div class="jieguo">{{scope.row.policy}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="打款金额(元)"></el-table-column>
				<el-table-column prop="money" label="操作">
					<template slot-scope="scope">
						<el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
							<el-button slot="reference">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div> -->
	</div>
</template>

<script>
import CounterList from './counterList.vue'
export default {
	data () {
		return {
			restaurants: [//列表
				{ value: "10000"},
				{ value: "20000"},
				{ value: "30000"},
			],
			zcrestaurants: [//政策列表
				{ value: "4.0"},
				{ value: "5.0"},
			],
        	state: '',//选择到的金额
			zcheradio:'',//政策
			jieguo:0,//计算结果
			
		}
	},
	components:{
		CounterList
	},
	mounted(){
	},

	methods: {
		querySearch(queryString, cb) {
			// 调用 callback 返回建议列表的数据
			cb(this.restaurants);
			this.oncounter()
		},
		handleSelect(item) {
			this.state = item.value
			this.oncounter()
		},
		onfocus(){
			this.state = ''
		},
		//政策
		zcquerySearch(queryString, cb) {
			// 调用 callback 返回建议列表的数据
			cb(this.zcrestaurants);
			this.oncounter()
		},
		zchandleSelect(item) {
			this.zcheradio = item.value
			this.oncounter()
		},
		onzcfocus(){
			this.zcheradio = ''
		},
		onjisqCopy(){
			this.$message.success("内容已复制到剪切板！")
		},
		// 计算
		oncounter(){
			if(this.state == '' || this.zcheradio == ''){return}
			this.jieguo = (this.state/(1 + this.zcheradio/100)).toFixed(2)
		},
		//广告币计算上传
		onupdateAdv(){
			if(this.state == ''){this.$message({message: '请填写广告币', type: 'warning'}); return}
			var prame = {
				adv:this.state,
				policy:this.zcheradio,
				money:this.jieguo,
			}
			this.$service.get(this.$api.updateAdv,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '保存成功', type: 'success'})
					this.state = '',
					this.jieguo = 0,
					this.$refs.CounterList.ongetAdvList(1)
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.counter{
	.counter-main{
		margin-top: 20px;
		display: flex;
		align-items: end;
		.gongdan{
			width: 50%;
			.dan{
				justify-content: left;
				margin-bottom: 50px;
				.inputbox{
					margin-left: 10px;
					.jieguo{
						color: #f00;
						font-size: 42px;
						font-weight: 500;
					}
				}
			}
		}
	}
	.conterlist{
		margin-top: 20px;
	}
}
@media screen and (max-width: 1250px) {
	.counter{
		.counter-main{
			display: block;
		}
	}
}
@media screen and (max-width: 750px) {
	.counter{
		.counter-main{
			display: block;
			.gongdan{
				width: 100%;
				.dan{
					margin-bottom: 20px;
					height: .6rem;
					justify-content: space-between;
				}
				.dan:last-child .text{
					margin-top: 0;
				}
			}
			
		}
	}
}
</style>
