<template>
	<div class="conterlist" id="list">
		
		<el-table :data="conterlist" style="width: 100%">
			<el-table-column prop="created_at" label="保存时间" >
				<template slot-scope="scope">
					{{(scope.row.created_at).substr(0,10)}}
				</template>
			</el-table-column>
			<el-table-column prop="adv" label="到账广告币" >
				<template slot-scope="scope">
					{{(scope.row.adv)}}
				</template>
			</el-table-column>
			<el-table-column prop="policy" label="政策" >
				<template slot-scope="scope">
					<div class="jieguo">{{scope.row.policy}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="money" label="打款金额(元)">
				<template slot-scope="scope">
					<div class="fuzhi" v-clipboard:copy="scope.row.money" v-clipboard:success="onjisqCopy">{{scope.row.money}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="money" label="操作">
				<template slot-scope="scope">
					<el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
						<el-button slot="reference">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
	</div>
</template>

<script>
export default {
	data () {
		return {
			conterlist:[],
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
		}
	},

	mounted(){
		this.ongetAdvList(1)
	},

	methods: {
		// 广告币计算列表
		ongetAdvList(val){
			var prame = {
				current_page:val,
				limit:this.per_page,
			}
			this.$service.get(this.$api.getAdvList,prame, (res)=> {
				if(res.code == '200'){
					this.conterlist = res.data.list
					this.lvtotal = res.data.count
				}
			})
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetAdvList(val)
		},
		//确认删除
		onEnter(row){
			var prame = {
				id:row.id,
			}
			this.$service.get(this.$api.delAdv,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '删除成功', type: 'success'})
					this.ongetAdvList(1)
				}
			})
		},
		onjisqCopy(){
			this.$message.success("内容已复制到剪切板！")
		},
	}
}
</script>

<style lang="scss" scoped>
.conterlist{
	width: 100%;
	.jieguo{
		color: #f00;
		font-size: 20px;
	}
}
@media screen and (max-width: 750px) {
	.conterlist{
		width: 100%;
	}
}
</style>
<style lang="scss">
.conterlist{
	.el-table{
		.fuzhi{
			cursor: pointer;
		}
	}
	.cell{
		text-align: center;
	}
}
</style>
